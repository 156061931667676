import React, { useEffect } from "react";
import { observe } from "selector-observer";
import { render } from "react-dom";

import { Karousel } from "../../../../scripts/react/Carousels";
import { DefaultClasses } from "../../../../scripts/react/Carousels/defaults";

export const ProviderCard = (props) => {
    const {
        name,
        imgSrc,
        imgAlt,
        imgShape,
        ctaLabel,
        ctaUrl,
        positions
    } = props;

    return (
        <article className="feature-provider-card">
            <img className={imgShape} alt={imgAlt} src={imgSrc} />
            <div>
                <span>{name}</span>
                <hr />
                {positions && 
                    <ul>
                        {positions.map(function (name, index) {
                            return <li key={index}>{name}</li>;
                        })}
                    </ul>
                }
                <a title={name} aria-label={name} href={ctaUrl}>{ctaLabel}</a>
            </div>
        </article>
    )
}


export const ProviderCards = (props) => {
    const { disableAnimation, cards } = props;
    if (!cards?.length) return null;
    const karouselOptions = getKarouselOptions(cards.length);
    return (
        <Karousel animationDisabled={disableAnimation} {...karouselOptions}>
            {cards.map((card, index) => (
                <ProviderCard {...card} key={index} />
            ))}
        </Karousel>
    );
}

const getKarouselOptions = (length: number) => {
    return {
      autoplay: false,
      autoplaySpeed: 3000,
      buttons: false,
      classes: DefaultClasses,
      draggable: true,
      indicators: length > 1,
      responsive: [
        {
          breakpoint: 1200,
          options: { slidesToShow: 3, buttons: length > 3, indicators: length > 3 },
        },
        {
          breakpoint: 768,
          options: { slidesToShow: 3, buttons: length > 3, indicators: length > 3 },
        },
      ],
      slidesToScroll: 1,
      slidesToShow: 1.5,
      speed: 300,
    };
  };
  

document.addEventListener("readystatechange", () => {
    if (document.readyState === "interactive") {
        observe('[data-component="providercards"]', {
            add(element) {
                const { dataset } = element as HTMLElement;
                const { propsId } = dataset;
                const componentData = window.ucsdh[`providercards_${propsId}`];
                render(<ProviderCards {...componentData} />, element);
            },
        });
    }
});